import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

import Edit from '@mui/icons-material/Edit';
import HomeRepairService from '@mui/icons-material/HomeRepairService';

import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';

import axios from '../axiosClient.js';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import DialogTitleBar from './common/DialogTitleBar.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import ConfirmDisable from './ConfirmDisable.jsx';

function EquipmentForm({
  // Props
  equipment,
  equipmentList,
  setTableReload,
}) {
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  const formTitle = React.useMemo(
    () => (equipment?.Serial ? 'Update Equipment' : 'Add Equipment'),
    [equipment?.Serial]
  );
  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const defaultValues = React.useMemo(
    () => ({
      equipmentSerial: equipment?.Serial || '',
      name: equipment?.Name || '',
      location: equipment?.Location || '',
      manufacturer: equipment?.Manufacturer || '',
    }),
    [equipment]
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = React.useCallback(
    async (data) => {
      // Disable the submit button and display the spinner
      setLoading(true);

      try {
        const isUpdate = !!equipment?.Id;
        let method;
        let url;

        if (isUpdate) {
          method = 'PUT';
          url = `/api/equipment/${equipment.Id}`;
        } else {
          method = 'POST';
          url = '/api/equipment/';
        }

        await axios({
          method,
          url,
          data: {
            disabled: equipment?.IsDisabled ?? false,
            serial: data.equipmentSerial,
            version: equipment?.Version,
            ...data,
          },
        });

        // Reset and close the modal
        reset(defaultValues);
        setOpen(false);

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }

        setSuccess('Successfully saved changes!');
      } catch (err) {
        setError(err.response?.data?.error || err.message);
      }
      setLoading(false);
    },
    [
      equipment?.IsDisabled,
      equipment?.Id,
      reset,
      defaultValues,
      setTableReload,
      setSuccess,
      equipment?.Version,
    ]
  );

  const onEnableOrDisableConfirmation = React.useCallback(async () => {
    await onSubmit({
      ...defaultValues,
      disabled: !equipment?.IsDisabled,
    });
  }, [defaultValues, equipment?.IsDisabled, onSubmit]);

  const handleOpen = React.useCallback((event) => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    reset(defaultValues);
    setOpen(false);
  }, [reset, defaultValues]);

  const rules = React.useMemo(
    () => ({
      maxLength: {
        value: 255,
        message: 'Manufacturer should be less than 256 characters',
      },
    }),
    []
  );

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      {equipment?.Serial ? (
        <IconButton
          color="primary"
          onClick={handleOpen}
          data-cy={`edit-equipment-row-${equipment?.Serial}`}
        >
          <Edit />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpen}
          startIcon={<HomeRepairService />}
          data-cy="add-equipment"
          fullWidth
        >
          Add Equipment
        </Button>
      )}
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        id="equipmentForm"
        onClick={disablePropagation}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitleBar title={formTitle} Icon={HomeRepairService} iconColor="secondary" />
        <DialogContent>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormStringInput
                control={control}
                name="name"
                label="Name"
                required
                disabled={defaultValues.disabled}
                rules={{
                  required: 'Please provide a name for the equipment',
                  maxLength: {
                    value: 255,
                    message: 'Name should be less 256 than characters',
                  },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormStringInput
                disabled={defaultValues.disabled}
                control={control}
                name="location"
                label="Location"
                required
                rules={{
                  required: 'Please provide a location for the equipment',
                  maxLength: {
                    value: 255,
                    message: 'Location should be less 256 than characters',
                  },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormStringInput
                control={control}
                name="equipmentSerial"
                label="Serial Number"
                required
                rules={{
                  required: 'Please provide the serial number of the equipment',
                  maxLength: {
                    value: 255,
                    message: 'Serial Number should be less than 256 characters',
                  },
                  validate: {
                    unique: (serial) => {
                      // If we are updating, remove ourselves from the list so we aren't checking ourselves
                      if (
                        equipmentList.some(
                          (equip) => equip.Serial !== equipment?.Serial && equip.Serial === serial
                        )
                      ) {
                        return 'Equipment with this Serial Number already exists';
                      }
                      return true;
                    },
                  },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormStringInput
                disabled={defaultValues.disabled}
                control={control}
                name="manufacturer"
                label="Manufacturer"
                rules={rules}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 2, mr: 'auto' }}>
            {!!equipment && (
              <ConfirmDisable
                handleConfirmation={onEnableOrDisableConfirmation}
                confirmKeyword={equipment.Serial}
                enable={equipment.IsDisabled}
                name="equipment"
                Icon={HomeRepairService}
              />
            )}
          </Box>
          <Box sx={{ m: 2 }}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{ m: 2 }}>
            <LoadingButton
              id="submitEquipment"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              {equipment?.Serial ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EquipmentForm;
