import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Edit from '@mui/icons-material/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import axios from '../axiosClient.js';
import DocumentContext from '../contexts/DocumentContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import DialogTitleBar from './common/DialogTitleBar.jsx';
import FormAutocompleteInput from './common/FormAutocompleteInput.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import ConfirmDisable from './ConfirmDisable.jsx';

function MaintenanceRequirementsForm({
  // props
  equipment,
  requirement,
  setTableReload,
}) {
  const [open, setOpen] = React.useState(false);

  const [error, setError] = React.useState(null);
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  const [loading, setLoading] = React.useState(false);

  const { documentOptions } = React.useContext(DocumentContext);

  const defaultValues = React.useMemo(
    () => ({
      // if the interval unit is unknown due to a migration, require the field to be filled out
      intervalUnits: requirement?.IntervalUnits || '',
      intervalPeriod: requirement?.IntervalPeriod || '',
      procedure: documentOptions.find((document) => document.id === requirement?.ProcedureId) || '',
      notes: requirement?.Notes || '',
    }),
    [
      documentOptions,
      requirement?.IntervalPeriod,
      requirement?.IntervalUnits,
      requirement?.Notes,
      requirement?.ProcedureId,
    ]
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    shouldUnregister: true,
  });
  const { isDirty } = useFormState({ control });

  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => {
    setOpen(false);
    reset();
  }, [reset]);
  const onSubmit = React.useCallback(
    async (data) => {
      // disable the submit button and display the spinner
      setLoading(true);

      try {
        const encodedId = encodeURIComponent(equipment.Id);
        if (requirement) {
          await axios({
            method: 'PUT',
            url: `/api/equipment/${encodedId}/maintenance-requirements/${requirement.Id}`,
            data: {
              interval: { period: data.intervalPeriod, units: data.intervalUnits },
              procedureId: data.procedure.id,
              disabled: data.disabled ?? requirement?.IsDisabled ?? false,
              notes: data.notes,
            },
          });
        } else {
          await axios({
            method: 'POST',
            url: `/api/equipment/${encodedId}/maintenance-requirements`,
            data: {
              interval: { period: data.intervalPeriod, units: data.intervalUnits },
              procedureId: data.procedure.id,
              disabled: false,
              notes: data.notes,
            },
          });
        }

        // reset and close the modal
        handleClose();

        // update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }
        setSuccess(
          requirement
            ? `Successfully edited a Maintenance Requirement on ${equipment.Name}`
            : `Successfully added a new Maintenance Requirement to ${equipment.Name}`
        );
      } catch (err) {
        setError(err.response?.data?.error || err.message);
      }
      setLoading(false);
    },
    [equipment.Name, equipment.Id, requirement, setSuccess, setTableReload, handleClose]
  );

  const intervalUnitOptions = React.useMemo(() => {
    const options = [
      { name: 'Days', id: 'Days' },
      { name: 'Operating Hours', id: 'Operating Hours' },
      { name: 'Months', id: 'Months' },
      { name: 'Weeks', id: 'Weeks' },
      { name: 'Years', id: 'Years' },
    ];

    // if the unit is defined as 'unknown' because of a migration, add the option
    // but require the user to update it
    if (requirement?.IntervalUnits?.toLowerCase() === 'unknown') {
      options.push({
        name: <i>Unknown</i>,
        id: 'Unknown',
        disabled: true,
      });
    }

    return options;
  }, [requirement?.IntervalUnits]);

  const handleDisableConfirmation = React.useCallback(async () => {
    await onSubmit({
      ...defaultValues,
      disabled: !requirement?.IsDisabled,
    });
  }, [defaultValues, onSubmit, requirement?.IsDisabled]);

  return (
    <>
      {requirement ? (
        <Tooltip title="Edit this maintenance requirement" arrow>
          <IconButton
            aria-label="edit-maintenance-requirement"
            color="secondary"
            onClick={handleOpen}
            data-cy={`edit-maintenance-requirement-${equipment.Serial}-${requirement.IntervalPeriod}`}
            disabled={!documentOptions.length}
          >
            <Edit />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Add a maintenance requirement" arrow>
          <IconButton
            aria-label="add"
            color="secondary"
            onClick={handleOpen}
            data-cy="add-requirements"
            disabled={!documentOptions.length}
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        data-cy="maintenance-requirements-form"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="dialog-error" />
        <DialogTitleBar
          title={`${requirement ? 'Edit' : 'Add'} Maintenance Requirement - ${equipment.Name} (${equipment.Serial})`}
          Icon={PendingActionsIcon}
          iconColor="secondary"
        />
        <DialogContent>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid size={6}>
              <FormStringInput
                control={control}
                name="intervalPeriod"
                label="Interval Period"
                type="number"
                textAlign="right"
                required
                disabled={requirement?.IsDisabled}
                rules={{
                  required: 'Please enter the period for this maintenance interval',
                }}
              />
            </Grid>
            <Grid size={6}>
              <FormStringInput
                control={control}
                name="intervalUnits"
                label="Interval Units"
                required
                disabled={requirement?.IsDisabled}
                options={intervalUnitOptions}
                rules={{
                  required: 'Please select the units for this maintenance interval',
                  validate: {
                    validateIntervalUnit: (value) => {
                      if (value?.toLowerCase() === 'unknown') {
                        return 'Unknown is not a maintenance interval';
                      }
                      return true;
                    },
                  },
                }}
              />
            </Grid>
            <Grid size={12}>
              <FormAutocompleteInput
                control={control}
                name="procedure"
                label="Procedure"
                required
                options={documentOptions}
                disabled={requirement?.IsDisabled}
                rules={{
                  required: 'Please enter the document ID for this maintenance procedure',
                }}
                data-cy="procedure-id-input"
              />
            </Grid>
            <Grid size={12}>
              <FormStringInput control={control} name="notes" label="Notes" rows={4} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 2, mr: 'auto' }}>
            {!!requirement && (
              <ConfirmDisable
                handleConfirmation={handleDisableConfirmation}
                enable={requirement.IsDisabled}
                confirmKeyword={requirement.IsDisabled ? 'ENABLE' : 'DISABLE'}
                name="requirement"
                Icon={PendingActionsIcon}
              />
            )}
          </Box>
          <Box sx={{ m: 2 }}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{ m: 2 }}>
            <LoadingButton
              data-cy="submit-requirements"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              {requirement ? 'Edit' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MaintenanceRequirementsForm;
