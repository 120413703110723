import React from 'react';
import PropTypes from 'prop-types';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import DocumentContext from '../../contexts/DocumentContext.jsx';

function DocumentLink({
  // Props
  documentId,
  displayCaption = false,
  'data-cy': dataCy,
}) {
  const { docUrl } = React.useMemo(() => window.globalConfig, []);

  const blockClick = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const { documents } = React.useContext(DocumentContext);
  const documentData = React.useMemo(
    () => documents.find((doc) => doc.id === documentId) || '',
    [documents, documentId]
  );
  if (!documentId) {
    return '-';
  }

  return (
    <>
      {displayCaption && (
        <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
          Document Title
        </Typography>
      )}

      {documentData.name ? (
        <Link
          href={`${docUrl}${documentData.id}`}
          data-cy={dataCy}
          target="_blank"
          rel="noopener"
          onClick={blockClick}
          sx={{ alignItems: 'center' }}
        >
          {documentData.id} - {documentData.name} <OpenInNewIcon fontSize="inherit" />
        </Link>
      ) : (
        <LinearProgress sx={{ width: '80%', mt: '1em' }} color="secondary" data-cy="loading-bar" />
      )}
    </>
  );
}

DocumentLink.propTypes = {
  documentId: PropTypes.number,
  displayCaption: PropTypes.bool,
  'data-cy': PropTypes.string,
};

export default React.memo(DocumentLink);
