import React from 'react';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { DateTime } from 'luxon';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import StraightenIcon from '@mui/icons-material/Straighten';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import axios from '../axiosClient.js';
import DocumentContext from '../contexts/DocumentContext.jsx';
import UserContext from '../contexts/UserContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import TestSamplesInput from '../pages/ControlledProcesses/TestSamplesInput.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import DialogTitleBar from './common/DialogTitleBar.jsx';
import FormAutocompleteInput from './common/FormAutocompleteInput.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import { readableControlledProcess } from './common/ReadableControlledProcess.jsx';
import { readablePart } from './common/ReadablePart.jsx';
import ControlChart from './ControlChart.jsx';
import EmployeeForm from './EmployeeForm.jsx';

function MeasurementsForm({
  setTableReload,
  partList,
  processes,
  isFullWidth,
  defaultProcess,
  'data-cy': dataCy,
}) {
  const { user } = useAuth0();
  const { documents } = React.useContext(DocumentContext);
  const [, setSuccess] = useAlertSnackbar('success', 5000);
  const [error, setError] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { users, userOptions } = React.useContext(UserContext);

  const processOptions = React.useMemo(
    () =>
      processes.map((process) => ({
        id: process.Id,
        name: readableControlledProcess({ process, documents }),
      })),
    [documents, processes]
  );

  const partsOptions = partList.map((part) => ({
    name: readablePart({ part }),
    id: part?.PartNumber,
  }));

  // Only update the default date value when opening the form to avoid console warnings
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultDate = React.useMemo(() => DateTime.now(), [open]);
  const defaultValues = React.useMemo(
    () => ({
      createdBy: userOptions.find(({ email }) => email === user?.email) || '',
      productPartNumber: '',
      lotIdentifier: '',
      // An array of length 100 was arbitrarily picked as being 10x expected lengths
      testSamples: new Array(100).fill(''),
      controlledProcess: processOptions.find(({ id }) => id === defaultProcess?.Id) || null,
    }),
    [processOptions, defaultProcess?.Id, userOptions, user?.email]
  );

  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues,
    shouldUnregister: true,
  });
  const { isDirty } = useFormState({ control });

  const selectedProcessId = watch('controlledProcess', defaultValues.controlledProcess);
  const rawTestSamples = useWatch({ control, name: 'testSamples' });
  const selectedInspector = watch('createdBy');

  const testSamples = React.useMemo(
    () => rawTestSamples?.map((sample) => (sample == null ? 0 : sample)) || [],
    [rawTestSamples]
  );

  const { selectedSampleCount, selectedTestUnits, testMin, testMax } = React.useMemo(() => {
    const selectedProcess = processes.find((process) => process.Id === selectedProcessId?.id);
    return {
      selectedSampleCount: selectedProcess?.RequiredSampleCount ?? 0,
      selectedTestUnits: selectedProcess?.TestUnits ?? '',
      testMin: selectedProcess?.TestMinimum ?? null,
      testMax: selectedProcess?.TestMaximum ?? null,
    };
  }, [processes, selectedProcessId?.id]);

  const handleOpen = React.useCallback(
    (event) => {
      event.stopPropagation();
      setOpen(true);
      reset(defaultValues);
    },
    [defaultValues, reset]
  );
  const handleClose = React.useCallback(() => {
    reset(defaultValues);
    setError(null);
    setOpen(false);
  }, [defaultValues, reset]);

  const onSubmit = React.useCallback(
    async (data) => {
      setLoading(true);
      setError(null);
      try {
        const encodedId = encodeURIComponent(data.controlledProcess.id);
        await axios.post(`/api/measurements/${encodedId}`, {
          createdBy: data.createdBy.id,
          dateOfCollection: defaultDate.toISO(),
          productPartNumber: data.productPartNumber.id,
          lotIdentifier: data.lotIdentifier,
          testSamples: data.testSamples.map((sample) => Number(sample)),
        });
        setSuccess('Measurement successfully recorded');
        handleClose();
        reset(defaultValues);
        setTableReload(true);
      } catch (err) {
        setError(err?.response?.data?.message || err?.message);
      } finally {
        setLoading(false);
      }
    },
    [defaultDate, defaultValues, handleClose, reset, setSuccess, setTableReload]
  );

  // --------------------------------------------------------------------------
  // Rules for required fields
  // --------------------------------------------------------------------------
  const rules = React.useMemo(
    () => ({
      controlledProcess: { required: 'Please select a Controlled Process!' },
      createdBy: { required: 'User is required!' },
      productPartNumber: { required: 'Product Part Number is required!' },
      lotIdentifier: { required: 'Lot identifier is required!' },
    }),
    []
  );

  return (
    <>
      {defaultProcess?.Id ? (
        <Tooltip title="Record Measurement" arrow>
          <IconButton
            aria-label="add"
            color="secondary"
            onClick={handleOpen}
            data-cy={dataCy}
            disabled={!documents.length || !users.length}
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<StraightenIcon />}
          onClick={handleOpen}
          data-cy={dataCy}
          disabled={!documents.length || !users.length}
          fullWidth={isFullWidth}
        >
          Add Measurement
        </Button>
      )}
      <Dialog
        open={open}
        maxWidth="lg"
        data-cy="measurements-form"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitleBar title="Add New Measurement" Icon={StraightenIcon} iconColor="secondary" />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid size={12}>
              <Grid container spacing={2}>
                <Grid
                  size={{ xs: 12, md: 6 }}
                  container
                  spacing={2}
                  data-cy="measurement-form"
                  sx={{ alignItems: 'center' }}
                >
                  <Grid size={12}>
                    <FormAutocompleteInput
                      control={control}
                      name="controlledProcess"
                      label="Controlled Process"
                      required
                      disabled={!!defaultProcess?.Id}
                      options={processOptions}
                      rules={rules.controlledProcess}
                    />
                  </Grid>
                  <Grid size={7}>
                    <FormAutocompleteInput
                      control={control}
                      name="createdBy"
                      label="Sample Inspector"
                      required
                      options={userOptions}
                      rules={rules.createdBy}
                    />
                  </Grid>
                  <Grid size={5}>
                    <EmployeeForm
                      selectedUser={selectedInspector}
                      users={users}
                      setTableReload={setTableReload}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <FormAutocompleteInput
                      control={control}
                      name="productPartNumber"
                      label="Product Part Number"
                      required
                      options={partsOptions}
                      rules={rules.productPartNumber}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <FormStringInput
                      control={control}
                      name="lotIdentifier"
                      label="Lot Identifier"
                      required
                      rules={rules.lotIdentifier}
                    />
                  </Grid>
                  <Grid size={12}>
                    <TestSamplesInput
                      control={control}
                      testUnits={selectedTestUnits}
                      sampleCount={selectedSampleCount}
                    />
                  </Grid>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} data-cy="measurements-chart">
                  <ControlChart
                    process={defaultProcess}
                    processId={selectedProcessId?.id}
                    testMin={testMin}
                    testMax={testMax}
                    liveData={testSamples}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <CancelButton onClick={handleClose} isDirty={isDirty} color="secondary">
              Cancel
            </CancelButton>
          </Box>
          <Box m={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              color="secondary"
              loading={loading}
              data-cy="submit-button"
            >
              Submit
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MeasurementsForm;
