import React from 'react';

import Button from '@mui/material/Button';

function ToggleButton({
  // Props
  'data-cy': dataCy,
  onClick,
  enabled,
  enabledIcon,
  disabledIcon,
  enabledMessage,
  disabledMessage,
}) {
  return (
    <Button
      variant="contained"
      color={enabled ? 'secondary' : 'primary'}
      onClick={onClick}
      startIcon={enabled ? enabledIcon : disabledIcon}
      data-cy={`${dataCy}-${enabled ? 'enabled' : 'disabled'}`}
      fullWidth
    >
      {enabled ? enabledMessage : disabledMessage}
    </Button>
  );
}

export default ToggleButton;
