import React from 'react';

import { useInterval } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';

const DATA_REFRESH_INTERVAL_MS = 15 * 60 * 1000;
const UserContext = React.createContext();

export function UserProvider({ children }) {
  const [error, setError] = useAlertSnackbar('error', 0);
  const [users, setUsers] = React.useState([]);

  const getUsers = React.useCallback(async () => {
    try {
      if (!error) {
        const { data } = await axios({
          method: 'GET',
          url: '/api/users',
        });

        setUsers(data);
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    }
  }, [error, setError]);
  useInterval(getUsers, DATA_REFRESH_INTERVAL_MS, !error);

  // Memoize the value to reduce rerenders
  const value = React.useMemo(
    () => ({
      users,
      userOptions: users.map(({ Id, FullName, Email, Version }) => ({
        id: Id,
        name: FullName,
        email: Email,
        version: Version,
      })),
      refreshUsers: getUsers,
    }),
    [users, getUsers]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserContext;
