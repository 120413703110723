import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { DateTime } from 'luxon';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import axios from '../axiosClient.js';
import DocumentContext from '../contexts/DocumentContext.jsx';
import UserContext from '../contexts/UserContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import DialogTitleBar from './common/DialogTitleBar.jsx';
import FormAutocompleteInput from './common/FormAutocompleteInput.jsx';
import FormDatePicker from './common/FormDatePicker.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import EmployeeForm from './EmployeeForm.jsx';

function MaintenanceActivityForm({ equipment, setTableReload }) {
  const { user } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { users, userOptions } = React.useContext(UserContext);

  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);
  const { documents } = React.useContext(DocumentContext);

  // form options
  const today = React.useMemo(() => DateTime.now(), []);
  const maintenanceRequirements = React.useMemo(
    () =>
      equipment?.MaintenanceRequirements.map((requirement) => {
        const document = documents.find(({ id }) => id === requirement.ProcedureId);
        const name = `${document?.name} - ${requirement.IntervalPeriod} ${requirement.IntervalUnits}`;
        return { name, id: requirement.Id, disabled: requirement.IsDisabled };
      }).sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      }),
    [documents, equipment?.MaintenanceRequirements]
  );

  const defaultValues = React.useMemo(() => {
    return {
      maintainer: userOptions.find(({ email }) => email === user?.email) || '',
      equipmentSerial: equipment.Serial || '',
      currentOperatingHours: '',
      maintenanceRequirements: [],
      notes: '',
      activityDate: today,
    };
  }, [equipment.Serial, today, userOptions, user?.email]);

  // require current operating hours field if any maintenance requirements depend on operating hours
  const includesOperatingHours = React.useMemo(
    () =>
      equipment.MaintenanceRequirements.some(
        (requirement) => requirement.IntervalUnits === 'Operating Hours'
      ),
    [equipment.MaintenanceRequirements]
  );

  // Form Submission
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues,
    shouldUnregister: true,
  });
  const { isDirty } = useFormState({ control });
  const selectedMaintainer = watch('maintainer');

  // Handles
  const handleOpen = React.useCallback(
    (event) => {
      event.stopPropagation();
      setError(null);
      setOpen(true);
      reset(defaultValues);
    },
    [defaultValues, reset]
  );
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);
  const onSubmit = React.useCallback(
    async (data) => {
      setLoading(true);
      try {
        const encodedId = encodeURIComponent(equipment.Id);
        await axios({
          method: 'POST',
          url: `/api/equipment/${encodedId}/maintenance-activity`,
          data: {
            activityDate: data.activityDate.toISODate(),
            maintenanceRequirementIds: data.maintenanceRequirements?.map((c) => c.id),
            notes: data.notes,
            maintainerId: data.maintainer.id,
            // if string is empty don't include it
            ...(data.currentOperatingHours.length && {
              currentOperatingHours: data.currentOperatingHours,
            }),
          },
        });
        // reset and close the modal
        handleClose();

        // update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }
        setSuccess(`Successfully added a new Maintenance Activity to ${equipment.Name}`);
      } catch (err) {
        setError(err.response?.data?.error || err.message);
      }
      setLoading(false);
    },
    [equipment.Name, equipment.Id, setSuccess, setTableReload, handleClose]
  );

  const maintainerRules = React.useMemo(
    () => ({
      required: 'Please enter the maintainer for this maintenance activity.',
    }),
    []
  );
  // Render
  return (
    <>
      <Tooltip title="Record a maintenance activity" arrow>
        <IconButton
          aria-label="add"
          color="secondary"
          onClick={handleOpen}
          data-cy="add-activity"
          disabled={!documents.length || !users.length}
        >
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        data-cy="maintenance-activity-form"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="dialog-error" />
        <DialogTitleBar
          title={`Add Maintenance Activity - ${equipment.Name} (${equipment.Serial})`}
          Icon={NoteAltIcon}
          iconColor="secondary"
        />
        <DialogContent>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Grid size={{ xs: 12, sm: 4 }}>
              {/* equipment serial number - prefilled with row's data and disabled */}
              <FormStringInput
                control={control}
                name="equipmentSerial"
                label="Serial Number"
                disabled
              />
            </Grid>
            <Grid data-cy="activity-date" size={{ xs: 12, sm: 4 }}>
              <FormDatePicker
                control={control}
                name="activityDate"
                label="Activity Date"
                required
                disableFuture
                rules={{
                  required: 'Please enter the date of this activity',
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              {/* current operating hours */}
              <FormStringInput
                control={control}
                name="currentOperatingHours"
                label="Current Operating Hours"
                type="number"
                required={includesOperatingHours}
                rules={{
                  required:
                    includesOperatingHours &&
                    'Please enter the current operating hours listed on the machine',
                  validate: {
                    validatePossibleTime: (value) => {
                      if (value < 0) {
                        return 'Please specify a positive time';
                      }
                      return true;
                    },
                  },
                }}
              />
            </Grid>
            <Grid size={{ xs: 7 }}>
              <FormAutocompleteInput
                control={control}
                data-cy="maintainer"
                label="Maintainer"
                name="maintainer"
                required
                options={userOptions}
                rules={maintainerRules}
              />
            </Grid>
            <Grid size={{ xs: 5 }}>
              <EmployeeForm
                selectedUser={selectedMaintainer}
                users={users}
                setTableReload={setTableReload}
              />
            </Grid>

            <Grid data-cy="maintenance-requirements" size={12}>
              {/* completed maintenance requirements
                  based on enabled or disabled maintenance requirements,
                  a multi select drop down */}
              <FormAutocompleteInput
                control={control}
                name="maintenanceRequirements"
                label="Maintenance Requirements"
                required
                multiple
                options={maintenanceRequirements}
                rules={{
                  required: 'Please select the maintenance requirement fulfilled by this activity',
                }}
              />
            </Grid>
            <Grid data-cy="activity-notes" size={12}>
              {/* notes field */}
              <FormStringInput control={control} name="notes" label="Notes" rows={4} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 2 }} data-cy="maintenance-form-cancel">
            <CancelButton
              color="secondary"
              isDirty={isDirty}
              onClick={handleClose}
              id="cancel-activities"
            >
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{ m: 2 }}>
            <LoadingButton
              data-cy="submit-activities"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              Add
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MaintenanceActivityForm;
