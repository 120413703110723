import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import isEmail from 'validator/es/lib/isEmail';

import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid2';

import { useCheckUnique } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';
import UserContext from '../contexts/UserContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import DialogTitleBar from './common/DialogTitleBar.jsx';
import FormStringInput from './common/FormStringInput.jsx';

function EmployeeForm({ selectedUser, users, setTableReload }) {
  const [open, setOpen] = React.useState(false);
  const [, setSuccess] = useAlertSnackbar('success', 5000);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { refreshUsers } = React.useContext(UserContext);
  const { user } = useAuth0();

  const userData = React.useMemo(() => {
    if (selectedUser) {
      const name = selectedUser?.name?.trim();
      const [firstName, lastName] = name ? name.split(' ') : ['', ''];
      return {
        id: selectedUser.id,
        firstName,
        lastName,
        email: selectedUser.email,
        version: selectedUser.version,
      };
    }
    return { email: user?.email };
  }, [selectedUser, user?.email]);

  const isEdit = Boolean(userData?.firstName || userData?.lastName);

  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const defaultValues = React.useMemo(() => {
    if (isEdit) {
      return {
        email: userData?.email || '',
        firstName: userData?.firstName || '',
        lastName: userData?.lastName || '',
      };
    }
    // If the logged user's email does not exist in the
    // list, default to creating a user for their email
    const emailExists = users.some((u) => u.Email === user?.email);
    return {
      email: !emailExists ? user?.email : '',
      firstName: '',
      lastName: '',
    };
  }, [isEdit, user?.email, userData, users]);

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const { isDirty } = useFormState({ control });

  const handleOpen = React.useCallback(
    (event) => {
      event.stopPropagation();
      reset(defaultValues);
      setOpen(true);
    },
    [reset, defaultValues]
  );

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = React.useCallback(
    async (data) => {
      setLoading(true);
      try {
        if (isEdit) {
          const encodedId = encodeURIComponent(userData.id);
          await axios.patch(`/api/users/${encodedId}`, {
            Email: data.email,
            FirstName: data.firstName,
            LastName: data.lastName,
            Version: userData.version,
          });

          setSuccess('Successfully updated user!');
        } else {
          await axios.post('/api/users', {
            Email: data.email,
            FirstName: data.firstName,
            LastName: data.lastName,
          });

          setSuccess('Successfully added new user!');
        }
        refreshUsers();
        setTableReload(true);
        setOpen(false);
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
      setLoading(false);
    },
    [setSuccess, setTableReload, isEdit, userData, refreshUsers]
  );

  const checkEmailUnique = useCheckUnique(users, 'Email', isEdit ? userData?.email : null);

  const validationRules = React.useMemo(() => {
    const validateUserEmail = (input) => {
      if (!isEmail(input)) {
        return 'Please enter a valid email address';
      }
      if (!/@tz(medical|monitoringsolutions)\.com$/.test(input)) {
        return 'Please enter a valid TZ Medical email address';
      }
      const uniqueCheckResult = checkEmailUnique(input);
      if (uniqueCheckResult !== true) {
        return 'This email is already registered!';
      }
      return true;
    };

    return {
      userEmail: {
        required: 'Please enter the user email',
        validate: validateUserEmail,
      },
      firstName: {
        required: "Please enter the user's first name",
      },
      lastName: {
        required: "Please enter the user's last name",
      },
    };
  }, [checkEmailUnique]);

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleOpen}
        startIcon={isEdit ? <PersonIcon /> : <PersonAddIcon />}
        data-cy="open-employee-form-button"
      >
        {isEdit ? 'Edit User' : 'Add User'}
      </Button>
      <Dialog
        open={open}
        onClick={disablePropagation}
        data-cy="add-user-form"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        {error && <Alert message={error} setMessage={setError} level="error" />}
        <DialogTitleBar
          title={isEdit ? 'Edit User' : 'Add User'}
          Icon={isEdit ? PersonIcon : PersonAddIcon}
          iconColor="secondary"
        />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid data-cy="first-name-input" size={12}>
              <FormStringInput
                control={control}
                name="firstName"
                label="First Name"
                required
                rules={validationRules.firstName}
              />
            </Grid>
            <Grid data-cy="last-name-input" size={12}>
              <FormStringInput
                control={control}
                name="lastName"
                label="Last Name"
                required
                rules={validationRules.lastName}
              />
            </Grid>
            <Grid data-cy="email-input" size={12}>
              <FormStringInput
                control={control}
                name="email"
                label="Email"
                required
                rules={validationRules.userEmail}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 2 }} data-cy="employee-form-cancel">
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{ m: 2 }}>
            <LoadingButton
              type="submit"
              loading={loading}
              disabled={!isDirty}
              variant="contained"
              color="secondary"
              data-cy="employee-submit-button"
            >
              Submit
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EmployeeForm;
